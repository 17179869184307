export const clientsData = [
  {
    id: 1,
    imageSrc: "./img/client1.jpeg",
    name: "Spartan India PVT LTD",
  },
  {
    id: 2,
    imageSrc: "./img/transcal.jpeg",
    name: "Transcal Technologies LLP",
  },
  {
    id: 3,
    imageSrc: "./img/chavank.jpeg",
    name: "Chavanke Engineering Pvt. Ltd.",
  },
  {
    id: 4,
    imageSrc: "./img/accurate.jpeg",
    name: "Accurate Services & Enterprises",
  },
  {
    id: 5,
    imageSrc: "./img/kesar.jpeg",
    name: "kesar Testing Services LLP",
  },
  {
    id: 6,
    imageSrc: "./img/client2.jpg",
    name: "Perfect Utilities",
  },
  {
    id: 7,
    imageSrc: "./img/client4.jpg",
    name: "Electrosteel Castings Limited",
  },
];

export const ourService = [
  {
    id: 1,
    image: "./img/service1.png",
    title: "Custom Software Development",
    description:
      "Our Specialised Customized Software Development is the process of Designing, Building, Deploying, and Maintaining Software for all types of SMEs and MSMEs that is tailored to their needs and aims",
  },
  {
    id: 2,
    image: "./img/service2.png",
    title: "App Development",
    description:
      "Our Application Development assist you in creating beneficial and efficient experiences on every device and help you get the most out of Mobile Technology for your business with our Application",
  },
  {
    id: 3,
    image: "./img/service3.png",
    title: "E-Commerce Solutions",
    description:
      "Our E-Commerce Software facilitates quicker and trusted growth of income and profit for both, beginner's playground, and an expert home field by meeting the demands of any small- to medium-sized firm.",
  },
  {
    id: 4,
    image: "./img/service4.png",
    title: "Software Consulting      ",
    description:
      "Trigun Software Consulting services diagnose, analyze, identify, plan, and offer solutions that are compatible with every organization's needs. Expertizing our knowledge and experience at all instances accelerates   ",
  },
  {
    id: 5,
    image: "./img/service5.png",
    title: "Cyber Secuirty",
    description:
      "To guarantee that your Systems and Data are safe, Trigun Cyber Security Employs a Patented Technique to execute a controlled external real-life evaluation and penetration test of your firewalls, Network",
  },
];

export const prductData = [
  {
    id: 1,
    image: "./img/lims.jpeg",
    title: "Laboratory Information Management System (LIMS )",
    description:
      "Calibration via Automation has become a necessity. Only through automation  the operation-running-cost can be brought down to Minimal. Lab city will bring complete automation to the calibration  task . Accurate Audit Documents , Errorless Reports ,Precise uncertainty Count And well projected Engineers work Reports ",
    subpoints:
      "1. Track your Customer’s calibration Instruments  & your Calibration Masters with  QR code labels, real-time Due alerts, etc.2.Onsite / Inhouse Offline Calibration : Take Lab City onsite even if there is no connectivity.Perform Calibration And get Auto -Generated Certificate From Raw Datasheet . when you are back in the network Offline Data Will Sync With Server Database . 3.Assign jobs to technicians based on Work . Track progress And Get Detailed  Work Report .       4.Automate uncertainties & CMC Validation .      5.Auto sends recall emails to clients  And Lab  For DUC Dues  , Master Dues. 6.Client Portal :  Deliver calibration certificates via customer self-service Portals . 7.Generate Calibration certificates and labels from raw data .Complies with the requirements of FDA 21 CFR PART 11 And ISO 17025.(highlight sentence )",
  },
  {
    id: 2,
    image: "./img/product1.png",
    title: "Scheduler",
    description:
      "A calibration schedule is an imperative part of any quality or safety program.  ",
    subpoints:
      "1.It helps employees and managers ensure that equipment is functioning properly and safely.  2.By creating and enforcing a calibration plan, users can rest assured that the measurements produced by the equipment are accurate. 3.Better lanning & Decision-Making  4.Easier organization 5.Faster Execution  6. Automated Documentation      Analysis capabilities      Cost reduction      Quality improvements.      Increase in efficiency.",
  },
  {
    id: 3,
    image: "./img/product1.png",
    title: "Smart AMC",
    description:
      "A calibration schedule is an imperative part of any quality or safety program.  ",
    subpoints:
      "1.It helps employees and managers ensure that equipment is functioning properly and safely.  2.By creating and enforcing a calibration plan, users can rest assured that the measurements produced by the equipment are accurate. 3.Better lanning & Decision-Making  4.Easier organization 5.Faster Execution  6. Automated Documentation      Analysis capabilities      Cost reduction      Quality improvements.      Increase in efficiency.",
  },
  {
    id: 4,
    image: "./img/product1.png",
    title: "Master QR",
    description:
      "A calibration schedule is an imperative part of any quality or safety program.  ",
    subpoints:
      "1.It helps employees and managers ensure that equipment is functioning properly and safely.  2.By creating and enforcing a calibration plan, users can rest assured that the measurements produced by the equipment are accurate. 3.Better lanning & Decision-Making  4.Easier organization 5.Faster Execution  6. Automated Documentation      Analysis capabilities      Cost reduction      Quality improvements.      Increase in efficiency.",
  },
];

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TiLocation } from "react-icons/ti";
import { IoIosCall } from "react-icons/io";
import { useMediaQuery } from 'react-responsive';

const Navbar = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const [open, setOpen] = useState(!isDesktop); 

  useEffect(() => {
    setOpen(!isDesktop); 
  }, [isDesktop]);

  const closeNavbar = () => {
    if(!isDesktop) setOpen(true);
  };
  return (
    <div className="antialiased bg-gray-100">
      <div className="w-full text-gray-700 bg-white">
        <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-5 lg:px-6">
          <div className="flex flex-row items-center justify-between p-4">
            <Link
              to="/"
              className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg  focus:outline-none focus:shadow-outline"
            >
              <img src="./img/logo.png" alt="logo" className="h-20" />
            </Link>
            <button
              className="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
              onClick={() => setOpen(!open)}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                {open ? (
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                )}
              </svg>
            </button>
          </div>
          <nav
            className={`flex-col flex-grow ${
              open ? "hidden" : "flex"
            } pb-4 md:pb-0 md:flex md:justify-end md:flex-row`}
          >
            <Link
              className="px-4 py-2 mt-2 text-md font-semibold bg-transparent md:mt-0 md:ml-4 hover:text-blue-700 hover:border-b-2 hover:border-blue-700 focus:outline-none focus:shadow-outline"
              to="/"
              onClick={closeNavbar}
            >
              Home
            </Link>
            <Link
              className="px-4 py-2 mt-2 text-md font-semibold bg-transparent md:mt-0 md:ml-4 hover:text-blue-700 hover:border-b-2 hover:border-blue-700 focus:outline-none focus:shadow-outline"
              to="/about"
              onClick={closeNavbar}
            >
              About US
            </Link>
            <Link
              className="px-4 py-2 mt-2 text-md font-semibold bg-transparent md:mt-0 md:ml-4 hover:text-blue-700 hover:border-b-2 border-b-2 border-white hover:border-blue-700 focus:outline-none focus:shadow-outline"
              to="/service"
              onClick={closeNavbar}
            >
              Service
            </Link>
            <Link
              className="px-4 py-2 mt-2 text-md font-semibold bg-transparent md:mt-0 md:ml-4 hover:text-blue-700 hover:border-b-2 border-b-2 border-white hover:border-blue-700 focus:outline-none focus:shadow-outline"
              to="/product"
              onClick={closeNavbar}
            >
              Product
            </Link>
            <Link
              className="px-4 py-2 mt-2 text-md font-semibold bg-transparent  md:mt-0 md:ml-4 hover:text-blue-700 hover:border-b-2 hover:border-blue-700 focus:outline-none focus:shadow-outline"
              to="/contact"
              onClick={closeNavbar}
            >
              Contact
            </Link>
            <Link
              className="px-4 py-2 mt-2 text-md font-semibold bg-transparent  md:mt-0 md:ml-4 hover:text-blue-700 hover:border-b-2 hover:border-blue-700 focus:outline-none focus:shadow-outline"
              to="/privacy"
              onClick={closeNavbar}
            >
              Privacy & Policy
            </Link>

            <div className="flex gap-3 items-center  md:ml-[6rem]">
              <p className="flex gap-3 items-center text-sm my-5 md:my-0">
                <TiLocation className="text-[#FF6600]" /> NAVI MUMBAI,INDIA
              </p>
              <p className="flex gap-3 items-center">
                <IoIosCall className="text-[#FF6600]" />
                +91 9769847865
              </p>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

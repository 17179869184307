import React from "react";
import { Link } from "react-router-dom";
import { prductData } from "../Data";

function OurProduct() {
  return (
    <section className=" py-10 px-12 w-[90%] mx-auto">
      <div className="grid grid-flow-row gap-x-20 gap-y-10 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {prductData.map((post, index) => (
          <div
            key={index}
            className="my-8 rounded shadow-lg shadow-gray-200 bg-[#F8F8F8] p-2 duration-300 hover:-translate-y-1 relative flex flex-col justify-between"
          >
            <figure>
              <div className="flex items-center justify-center ">
                <img
                  src={`${post.image}?auto=format&fit=crop&w=400&q=50`}
                  className="rounded-full h-[100px] w-[100px] object-cover absolute top-[-50px]  transition-transform transform scale-100 rotate-0 hover:scale-125 hover:rotate-45"
                  alt={post.title}
                />
              </div>
              <figcaption className="p-4 mt-12 text-center">
                <p className="text-lg mb-4 font-bold leading-relaxed text-gray-800">
                  {post.title}
                </p>
                <small className="leading-5 text-gray-500">
                  {post.description}
                </small>
              </figcaption>
            </figure>
            <div className="flex items-center justify-center my-3">
              <Link to={`/product/${post.id}`}>
                <span
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg_primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  Read more
                </span>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

const Product = () => {
  return (
    <div>
      <div
        className="h-[24rem] bg-cover relative bg-center flex items-center justify-start text-white"
        style={{
          backgroundImage: 'url("./img/product.jpeg")',
        }}
      >
        <h1 className="text-5xl font-bold text-white absolute top-1/3 left-1/4 sm:top-1/4 sm:left-1/3 md:top-1/4 md:left-1/4 lg:top-40 lg:left-28">
          Product
        </h1>
      </div>
      <div className="text-center w-[60%] m-auto py-3  font-teko my-10">
        <h5 className="text-blue-600 text-1xl font-[700] ">Our Products</h5>
        <h6 className="text-2xl md:text-4xl font-[800] mt-10">
          We Focused On Modern Product
        </h6>
      </div>
      {OurProduct()}
    </div>
  );
};

export default Product;

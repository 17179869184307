import React from "react";
import { useParams } from "react-router-dom";
import { prductData } from "../Data";

const SingleProduct = () => {
  const { id } = useParams();
  const product = prductData?.find((item) => item.id == id);
  const subpointsArray = product?.subpoints?.split(/\d+\./)?.filter(Boolean);
  if (!product) {
    return <div>Product not found</div>;
  }
  return (
    <div className="bg-gray-100 py-8 px-2">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row -mx-4">
          <div className="md:flex-1 px-4">
            <div className="h-[460px] rounded-lg bg-gray-200 mb-4">
              <img
                className="w-[90%] mx-auto h-full object-contain transform scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                src={`${product?.image}`}
                alt="Product Image"
              />
            </div>
          </div>
          <div className="md:flex-1 px-4">
            <h2 className="text-2xl font-bold mb-2">{product?.title}</h2>
            <div>
              <span className="font-bold text-gray-700">Product Description:</span>
              <p className="text-gray-600 text-sm mt-2">{product?.description}</p>
              <p className="text-black font-semibold text-sm mt-2">Details:</p>
              <ol className="list-decimal text-gray-600 text-sm mt-2 pl-4">
                {subpointsArray?.map((point, index) => (
                  <li key={index}>{point?.trim()}</li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;

import React from "react";
import AboutSection from "../Components/AboutSection";

const About = () => {
  return (
    <>
      <div
        className="h-[24rem] bg-cover relative bg-center flex items-center justify-start text-white"
        style={{
          backgroundImage: 'url("./img/aboutbanner.jpeg")',
        }}
      >
        <h1 className="text-5xl font-bold text-black absolute top-1/3 left-1/4 sm:top-1/4 sm:left-1/3 md:top-1/4 md:left-1/4 lg:top-40 lg:left-28">
          About Us
        </h1>
      </div>
      <AboutSection />
      <div className="w-[90%] mx-auto my-3 text-justify px-4">
        <p className="text-base text-slate-600	 text-justify">
          BFI with a strong dedication to our customers, in-depth knowledge of
          the industry, and a vast network of innovation & delivery units, we
          guarantee the greatest levels of assurance and satisfaction providing
          IT solutions that focuses on Technology, design, strategy,
          maintenance, services & support around the world.
          <br />
          <br />
          Our workforce is the “key to success” that ensures quality IT
          solutions to expand every business module through digitization
          delivering innovative solutions that are flexible, relevant, and
          essential for your company's success.
        </p>
      </div>
      <div
        className="h-auto bg-cover  bg-center  text-white py-5"
        style={{
          backgroundImage: 'url("./img/vissonmisson.jpg")',
        }}
      >
        <h2 className="text-lg md:text-5xl text-center py-5 w-[80%] mx-auto">
          BFI FUNDAMENTAL PRINCIPLES OF WORK SINCE ITS INCEPTION
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
          <div className="p-5 text-center">
            <h2 className="font-extrabold	leading-snug	 py-5 text-4xl font-teko">
              OUR VISION
            </h2>
            <p className="font-extrabold text-2xl">
              Deliver Solution Through Innovation
            </p>
            <p className="font-extrabold text-2xl mt-5">
              Innovating Tomorrow's Tech
            </p>
            <p className="font-extrabold text-2xl mt-5">
              Inspiring Tech Innovation
            </p>
            <p className="font-extrabold text-2xl mt-5">
              Connecting the Future{" "}
            </p>
          </div>
          <div className="p-5 text-center">
            <h2 className="font-extrabold	leading-snug	 py-5 text-4xl font-teko">
              OUR MISSION
            </h2>
            <p className="font-extrabold text-2xl">
              Empower Digital Solutions{" "}
            </p>
            <p className="font-extrabold text-2xl mt-5">
              Connect People, Tech{" "}
            </p>
            <p className="font-extrabold text-2xl mt-5">
              Simplify IT Challenges{" "}
            </p>
            <p className="font-extrabold text-2xl mt-5">
              Inspire Digital Growth{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

import React from "react";
import { ourService } from "../Data";

function OurService() {
  return (
    <section className=" py-10 px-10 w-[90%] mx-auto">
      <div className="grid grid-flow-row gap-x-20 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {ourService.map((post, index) => (
          <div
            key={index}
            className="my-8 rounded shadow-lg shadow-gray-200 bg-[#F8F8F8] p-2 duration-300 hover:-translate-y-1"
          >
            <figure className=" text-center">
              <div className="flex items-center justify-center">
                <img
                  src={post.image}
                  className="rounded-t h-16 w-[100px] object-cover"
                  alt={post.title}
                />
              </div>
              <figcaption className="p-4">
                <p className="text-lg mb-4 font-bold leading-relaxed text-gray-800">
                  {post.title}
                </p>
                <small className="leading-5 text-gray-500 ">
                  {post.description}
                </small>
              </figcaption>
            </figure>
            <div className="flex items-center justify-center my-3">
              {/* <button className="inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 ">
                <span className="relative px-5 py-2 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0 text-center inline-flex items-center">
                  Read More
                  <svg
                    className="w-3 h-3 ml-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </span>
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

const Service = () => {
  return (
    <div>
      {" "}
      <div
        className="h-[24rem] bg-cover relative bg-center flex items-center justify-start text-white"
        style={{
          backgroundImage: 'url("./img/service.jpg")',
        }}
      >
        <h1 className="text-5xl font-bold text-white absolute top-1/3 left-1/4 sm:top-1/4 sm:left-1/3 md:top-1/4 md:left-1/4 lg:top-40 lg:left-28">
          Service
        </h1>
      </div>
      <div className="text-center w-[60%] m-auto py-3  font-teko my-10">
        <h5 className="text-blue-600 text-1xl font-[700] ">OUR SERVICES</h5>
        <h6 className="text-2xl md:text-4xl font-[800] mt-10">
          Exceptional BFI Services with Reliability
        </h6>
      </div>
      {OurService()}
    </div>
  );
};

export default Service;

import React from "react";
import { Link } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";
const Footer = () => {
  return (
    <>
      <div className="py-5 bg-gradient-to-l from-blue-500 to-purple-900 text-white">
        <div className="container mx-auto text-center">
          <div className="flex flex-col lg:flex-row justify-center">
            <div className="w-full lg:w-8/12">
              <div className="flex items-center justify-center py-6">
                <p className="mb-0 text-center text-lg lg:text-xl xl:text-2xl font-semibold">
                  Our Unique Technological Solutions, We Transform Your Business
                  by Offering Automations that are, Reliable, Innovative, and
                  Creative.
                </p>
              </div>
            </div>
            <div className="w-full lg:w-4/12">
              <div className="flex items-center justify-center py-6">
                <Link to="/contact">
                  <button className="makean_enquiry">Make an Enquiry</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer
        className="bg-cover"
        style={{
          backgroundImage: 'url("./img/footerImage.jpg")',
        }}
      >
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
            <div className="mb-6 md:mb-0">
              <Link to="/" className="flex items-center">
                <img
                  src="./img/logo.png"
                  className="h-[100px] w-[130px] mr-3"
                  alt="FlowBite Logo"
                />
              </Link>
              <div>
                <p className="mb-4 flex items-center justify-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="mr-3 h-10 w-10 text-[#FF6600]"
                  >
                    <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                    <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                  </svg>
                  <span className="text-[15px]">
                  D2, 1/14, Artist Colony, Sector 8 CBD Belapur Navi Mumbai, 400614, Maharashtra.
                  </span>
                </p>
                <p className="mb-4 flex items-center justify-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="mr-3 h-5 w-5 text-[#FF6600]"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>
                  <span className="text-[15px]">info@bfiinfotech.co.in</span>
                </p>
                <p className="mb-4 flex items-center justify-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="mr-3 h-5 w-5 text-[#FF6600]"
                  >
                    <path
                      fillule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-[15px]">+91 9769847865</span>
                </p>
              </div>
            </div>{" "}
            <div>
              <h2 className="mb-6 text-md font-bold text-black uppercase ">
                QUICK LINKS
              </h2>
              <ul className="text-black  font-normal">
                <li className="mb-4 flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/about"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    About Us
                  </Link>
                </li>
                <li className="mb-4 flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/service"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Service
                  </Link>
                </li>
                <li className="mb-4 flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/product"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Product
                  </Link>
                </li>
                <li className="mb-4 flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/contact"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-md font-bold	 text-black uppercase ">
                OUR SERVICES
              </h2>
              <ul className="text-black font-normal		">
                <li className="mb-4 flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/service"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Custom Software Development
                  </Link>
                </li>
                <li className="mb-4 w-[200px] flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/service"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    App Development
                  </Link>
                </li>
                <li className="mb-4 w-[200px] flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/service"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    E-Commerce Solutions
                  </Link>
                </li>
                <li className="mb-4 w-[200px] flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/service"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Software Consulting
                  </Link>
                </li>
                <li className="mb-4 w-[200px] flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/service"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Cyber Secuirty
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-md font-bold text-black uppercase ">
                OUR PRODUCTS
              </h2>
              <ul className="text-black font-normal	">
                <li className="mb-4 w-[200px] flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/product/1"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Callibration
                  </Link>
                </li>
                <li className="mb-4 w-[200px] flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/product/2"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Scheduler
                  </Link>
                </li>
                <li className="mb-4 w-[200px] flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/product/3"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Smart AMC
                  </Link>
                </li>
                <li className="mb-4 w-[200px] flex items-center">
                  <BiSolidChevronRight className="text-[20px] font-bold text-[#FF6600]" />
                  <Link
                    to="/product/4"
                    className=" hover:text-blue-700 hover:tracking-wider	 ease-in duration-500"
                  >
                    Master QR
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
          <div className="flex items-center justify-center">
            <span className="text-sm text-black text-center font-semibold">
              © 2024{" "}
              <Link to="/" className="">
                BFI
              </Link>
              . All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

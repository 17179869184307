import React from "react";

const AuthorizedSales = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full p-6 font-toko bg-[#F7F9F2]">
      <h2 className="font-extrabold mt-5 mb-10 uppercase leading-snug text-center py-3 text-3xl md:text-4xl font-teko">
        Our Authorized Sales & Service Partners
      </h2>

      <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row w-full md:w-[60%] mx-auto p-2 ">
        <img
          className="object-contain h-28 w-full md:w-[45%] rounded-xl m-5"
          src="./img/sale1.jpeg"
          alt="Reckonix Sales Partner"
        />
        <div className="flex flex-col justify-between px-4 py-2 leading-normal w-full md:w-[55%]">
          <div className="max-w-md p-4 text-md">
            <p className="mb-2 text-gray-700">
              <strong>Reckonix</strong>
            </p>
            <p className="mb-2 text-gray-600 font-semibold">
              Unit F2, MIDC Plot no. BG/PAP3,
              <br />
              Opp. The Fern Residency Hotel, MIDC, Bhosari, Bachupally,
              Medchal-Malkajgiri,
              <br />
              Pune-411026, Maharashtra
            </p>
            <p className="mb-2 text-gray-600 font-semibold">
              Phone:{" "}
              <a
                href="tel:7095521126"
                className="text-blue-600 hover:underline"
              >
                7095521126
              </a>
            </p>
            <p className="text-gray-600 font-semibold">
              Email:{" "}
              <a
                href="mailto:sales@reckonix.in"
                className="text-blue-600 hover:underline"
              >
                sales@reckonix.in
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizedSales;
